<template>
  <router-view />
</template>
<script>
import { resetRem } from '@/utils/rem';
export default {
  name: "HomeView",
  created() {
    resetRem();
    // 改变窗口大小时重新设置 rem
    window.onresize = () => {
      resetRem();
    };
    let token = localStorage.getItem('token');
    if(token){
      this.$store.commit({
        type: 'setToken',
        token: token
      })
    }
  },
};
</script>
<style lang="scss">

*{
  margin: 0;
  padding: 0;
}
html,body{
  font-size: 20px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  width: 100%;
  height: 100%;
  box-sizing:border-box;
  background-color: #fff;
}

nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
