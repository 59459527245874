import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "./utils/global.css";
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
const app = createApp(App);
app.config.globalProperties.$httpUrl = 'https://yddpy.com'
app.use(ElementPlus);
// app.use(Bus);
app.use(store).use(router).mount("#app");
// 全局注册
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}


// router.beforeEach((to, from, next)=>{
//     if(to.meta.title){
//         document.title = to.meta.title
//     }
//     next()
// })

// app.use(ElementPlus).use(router).mount('#app')
