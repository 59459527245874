import { createStore } from "vuex";

export default createStore({
    state: {
        token:'',
        isLogin:false,
        userInfo:{},
    },
    getters: {
        // getToken: state => state.token
    },
    mutations: {
        setToken(state,data){
            state.token = data.token
            state.isLogin = true
            localStorage.setItem('token',data.token);
            localStorage.setItem('userInfo',data);
            localStorage.setItem('isLogin',true);
        },
        setUserInfo(state,data){
            state.userInfo = data.token
            localStorage.setItem('userInfo',JSON.stringify(data.token));
        },
        setImg(state,data){
            state.headUrl = data.headUrl
            localStorage.setItem('headUrl',JSON.stringify(data.headUrl));
        },
        outToken(state,data){
            state.isLogin = false
            state.token = data.token
            localStorage.setItem('isLogin',false);
            localStorage.removeItem('token')
            localStorage.removeItem('userInfo')
            // localStorage.removeItem('token')
        }
    },
    actions: {},
    modules: {},
});
