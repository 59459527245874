import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";


const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
        import("../views/index.vue"),
  },
  {
    path: "/dubbing",
    name: "Dubbing",
    component: () => import( "../views/dubbing.vue"),
  },
  {
    path: "/vip",
    name: "VIP",
    component: () =>
        import( "../views/vip.vue"),
  },
  {
    path: "/help",
    name: "Help",
    component: () =>
        import("../views/help.vue"),
  },
  {
    path: "/copyExtraction",
    name: "CopyExtraction",
    component: () =>
        import("../views/copyExtraction.vue"),
  },
  {
    path: "/copywriting",
    name: "copyWriting",
    component: () =>
        import("../views/copywriting.vue"),
  },
  {
    path: "/watermark",
    name: "homeWatermark",
    component: () =>
        import("../views/watermark.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
        import("../views/login.vue"),
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () =>
        import("../views/privacy.vue"),
  },
  {
    path: "/agreement",
    name: "Agreement",
    component: () =>
        import("../views/agreement.vue"),
  },
  {
    path:'/user',
    name: "User",
    component: () => import("../views/user/index.vue"),
    redirect: '/user/index',
    children:[
      {
        path: '/user/index',
        name: 'userIndex',
        component: () => import("../views/user/home.vue"),
      },
      {
        path: '/user/consumption',
        name: 'Consumption',
        component: () => import("../views/user/consumption.vue"),
      },
      {
        path: '/user/sound',
        name: 'Sound',
        component: () => import("../views/user/sound.vue"),
      },
      {
        path: '/user/extract',
        name: 'Extract',
        component: () => import("../views/user/extract.vue"),
      },
      {
        path: '/user/watermark',
        name: 'Watermark',
        component: () => import("../views/user/watermark.vue"),
      },
      {
        path: '/user/watermark',
        name: 'Watermark',
        component: () => import("../views/user/watermark.vue"),
      },
      {
        path: '/user/overwrite',
        name: 'Overwrite ',
        component: () => import("../views/user/overwrite.vue"),
      },
    ]
  }
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  // base: '/test'
});

export default router;
